import renderPreviewFile from '@weights/customRender/renderPreviewFile'

import { empty as emptyFilter } from '@utils'
import { timeFilter, withYuanUnitAmountFilter } from '@utils/filter'

// `存缴金额` 字段
export const PAYMENT_AMOUNT_FIELD = 'cashPaymentAmount'

// 前置内容项配置
export const ADDON_BEFORE_COLUMNS = [
    {
        dataIndex: 'askCashPaymentAmount',
        label: '金额',
        customRender: val => withYuanUnitAmountFilter(val)
    },
    {
        dataIndex: 'operationTypeText',
        label: '办理方式',
        colStyle: {
            textAlign: 'right'
        },
        customRender: val => emptyFilter(val)
    }
]

// 主体内容项配置
export const CONTENT_COLUMNS = [
    {
        dataIndex: PAYMENT_AMOUNT_FIELD,
        label: '存缴金额',
        customRender: val => withYuanUnitAmountFilter(val)
    },
    {
        dataIndex: 'cashPaymentDate',
        label: '缴纳时间',
        customRender: val => timeFilter(val)
    },
    {
        dataIndex: 'bankCodeText',
        label: '开户行',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'bankFullNameCodeText',
        label: '开户行全称',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'accountName',
        label: '账户名称',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'accountNumber',
        label: '账户号码',
        customRender: val => emptyFilter(val)
    },
    {
        dataIndex: 'cashFile',
        label: '缴纳凭证',
        customRender: renderPreviewFile
    },
    {
        dataIndex: 'depositGreementFile',
        label: '存款协议书',
        customRender: renderPreviewFile
    }
]
