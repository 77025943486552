<template>
    <fragment>
        <with-addon-before-info-panel
            title="现金存缴"
            class="has-margin-top"
            :data-source="dataSource"
            :columns="contentColumns"
            :addon-before-columns="addonBeforeColumns"
        >
            <custom-button-space v-if="showOperation" slot="extra">
                <template v-if="cashProcessState">
                    <a-button
                        type="primary"
                        :loading="loading.paymentHandling"
                        @click.stop="handleClickPaymentHandling"
                    >
                        存缴办理
                    </a-button>
                </template>

                <template v-if="cashProcessShowState">
                    <a-button
                        type="primary"
                        :loading="loading.lookOver"
                        @click.stop="handleLookOverApplyInfo"
                    >
                        查看申请资料
                    </a-button>
                </template>

                <template v-if="cashAddState">
                    <a-button
                        type="primary"
                        :loading="loading.cashPayment"
                        @click.stop="handleClickCashPayment"
                    >
                        现金存缴
                    </a-button>
                </template>
            </custom-button-space>

            <template #emptySlot>
                <div class="fixed-deposit-info-panel--empty">{{ emptyText }}</div>
            </template>
        </with-addon-before-info-panel>

        <component
            :is="modalComponentName"
            v-bind="modalProp"
            @on-close="handleCloseModal"
            @on-refresh="() => $emit('on-refresh')"
        />
    </fragment>
</template>
<script>
import { Button as AButton } from 'ant-design-vue'
import Fragment from '@components/Fragment'
import CustomButtonSpace from '@components/CustomButtonSpace'
import WithAddonBeforeInfoPanel from '@weights/WithAddonBeforeInfoPanel'

import { isEmpty, hasOwnProperty } from '@utils'
import { parserPanel } from '@/weights/CreatePage/parse'

import { LOAD_LAYOUT_URL } from '@config'
import { CONTENT_COLUMNS, ADDON_BEFORE_COLUMNS, PAYMENT_AMOUNT_FIELD } from './config'

import { PROCESS_TYPE_MAPPING_EMPTY_TEXT } from '@constant/enum'

import commonService from '@service/commonService'
import getCashPaymentRecordService from '@service/marginManagement/marginPaymentDetail/getCashPaymentRecordService'
import getSpecialBankAccountInfoService from '@service/marginManagement/marginPaymentDetail/getSpecialBankAccountInfoService'
export default {
    name: 'CashPaymentPanel',
    components: {
        AButton,
        Fragment,
        CustomButtonSpace,
        WithAddonBeforeInfoPanel,
        CashPaymentModal: () => import('./CashPaymentModal'),
        HandlingApplyModal: () => import('./HandlingApplyModal'),
        LookOverApplyInfoModal: () => import('./LookOverApplyInfoModal')
    },
    props: {
        showOperation: {
            type: Boolean,
            default: true
        },
        dataSource: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            // 加载状态
            loading: {
                // 现金存缴
                cashPayment: false,
                // 存缴办理
                paymentHandling: false
            },

            // 弹框相关状态
            modalProp: {
                visible: false
            },
            modalComponentName: undefined,

            // 前置内容项配置
            addonBeforeColumns: Object.freeze(ADDON_BEFORE_COLUMNS)
        }
    },
    computed: {
        // 项目编号
        projectCode() {
            return this.$route.query.projectCode
        },
        // 订单编号
        orderNo() {
            return this.dataSource.orderNo
        },
        // 是否展示 `现金存缴` 按钮
        cashAddState() {
            return this.dataSource.cashAddState
        },
        // 是否展示 `存缴办理` 按钮
        cashProcessState() {
            return this.dataSource.cashProcessState
        },
        // 是否展示 `查看申请资料` 按钮
        cashProcessShowState() {
            return this.dataSource.cashProcessShowState
        },
        // 申请状态
        processType() {
            return this.dataSource.cashProcessType
        },
        // 存缴金额
        cashPaymentDate() {
            return this.dataSource[PAYMENT_AMOUNT_FIELD]
        },
        // 是否展示空文本
        needDisplayEmptyText() {
            if (isEmpty(this.cashPaymentDate)) return true
            return hasOwnProperty(PROCESS_TYPE_MAPPING_EMPTY_TEXT, this.processType)
        },
        // 空文本
        emptyText() {
            if (!this.needDisplayEmptyText) return
            return PROCESS_TYPE_MAPPING_EMPTY_TEXT[this.processType]
        },
        // 主体内容的字段配置
        contentColumns() {
            return this.needDisplayEmptyText ? [] : Object.freeze(CONTENT_COLUMNS)
        }
    },
    methods: {
        // 获取办理资料【申请】布局模板
        async getHandlingInfoApplyTpl() {
            try {
                const param = {
                    url: LOAD_LAYOUT_URL,
                    params: {
                        orderNo: this.orderNo,
                        projectCode: this.projectCode,
                        layoutCode: 'ent_margin_cash_apply_file_detail'
                    }
                }

                const result = await commonService(param)

                return Object.freeze(parserPanel(result))
            } catch (e) {
                // eslint-disable-next-line
                return Promise.reject({ method: 'error', message: e.message })
            }
        },
        // 获取办理资料【查看】布局模板
        async getHandlingInfoLookOverTpl() {
            try {
                const param = {
                    url: LOAD_LAYOUT_URL,
                    params: {
                        orderNo: this.orderNo,
                        layoutCode: 'ent_margin_cash_apply_file_41'
                    }
                }

                const result = await commonService(param)

                return Object.freeze(parserPanel(result))
            } catch (e) {
                // eslint-disable-next-line
                return Promise.reject({ method: 'error', message: e.message })
            }
        },
        // 获取银行专户信息
        async getSpecialBankAccountInfo() {
            try {
                // eslint-disable-next-line
                const param = { projectCode: this.projectCode }

                const info = await getSpecialBankAccountInfoService(param)

                return Object.freeze(info)
            } catch (e) {
                // eslint-disable-next-line
                return Promise.reject({ method: 'warning', message: e.message })
            }
        },
        // 点击现金存缴
        async handleClickCashPayment() {
            try {
                this.changeBtnLoading('cashPayment', true)

                // eslint-disable-next-line
                const param = { orderNo: this.orderNo }
                const record = await getCashPaymentRecordService(param)

                const extraProp = {
                    cashPaymentRecord: Object.freeze(record)
                }
                this.handleOpenModal('CashPaymentModal', extraProp)
            } catch (e) {
                this.$message.warning(e.message)
            } finally {
                this.changeBtnLoading('cashPayment', false)
            }
        },
        // 点击存缴办理
        async handleClickPaymentHandling() {
            try {
                this.changeBtnLoading('paymentHandling', true)

                const info = await this.getSpecialBankAccountInfo()
                const tpl = await this.getHandlingInfoApplyTpl()

                const extraProp = {
                    orderNo: this.orderNo,
                    handlingInfoTpl: tpl,
                    specialBankAccountInfo: info
                }
                this.handleOpenModal('HandlingApplyModal', extraProp)
            } catch (error) {
                this.$message[error.method]?.(error.message)
            } finally {
                this.changeBtnLoading('paymentHandling', false)
            }
        },
        // 查看申请资料
        async handleLookOverApplyInfo() {
            try {
                this.changeBtnLoading('lookOver', true)

                const info = await this.getSpecialBankAccountInfo()
                const tpl = await this.getHandlingInfoLookOverTpl()

                const extraProp = {
                    handlingInfoTpl: tpl,
                    specialBankAccountInfo: info
                }
                this.handleOpenModal('LookOverApplyInfoModal', extraProp)
            } catch (error) {
                this.$message[error.method]?.(error.message)
            } finally {
                this.changeBtnLoading('lookOver', false)
            }
        },
        changeBtnLoading(btnType, bool) {
            this.$set(this.loading, btnType, bool)
        },
        handleOpenModal(modalName, extraProp = {}) {
            this.modalProp = {
                visible: true,
                ...extraProp || {}
            }
            this.modalComponentName = modalName
        },
        handleCloseModal() {
            this.$set(this.modalProp, 'visible', false)
        }
    }
}
</script>
